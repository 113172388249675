import { FC, useEffect } from "react";
import { navigate } from "gatsby";

const Auth: FC = () => {
  useEffect(() => {
    navigate("/auth/login");
  }, []);
  return null;
};

export default Auth;
